import axios, {AxiosRequestConfig} from "axios";

export const externalClient = axios.create();

export const apiClient = axios.create({
    headers: {
        "Content-Type": "application/json",
        "Accept": "application/json"
    },
    adapter: undefined
});

export const setAccessToken = (accessToken: String) => {
    apiClient.defaults.headers['Authorization'] = `Bearer ${accessToken}`;
};

export const setAgentId = (agentId: string) => {
    apiClient.defaults.headers['x-agent-id'] = agentId;
};

export const setIsDemo = () => {
    apiClient.defaults.headers['x-simulator'] = "true";
};

export const setBluexpSource = () => {
    apiClient.defaults.headers['x-bluexp-source'] = "true";
};


export const recursiveGet = async (url: string, config?: AxiosRequestConfig) => {
    let hasMoreData = true;
    const returnValue = [];
    let count = 0;

    while (hasMoreData) {
        const { data } = await apiClient.get(url, config);
        hasMoreData = !!data.nextToken;
        count = data.count
        if(data.nextToken) {
            config = config || {};
            config.params = config.params || {};
            config.params.nextToken = data.nextToken;
        }
        returnValue.push(...data.items)
    }

    return {
        data: {
            count,
            items: returnValue,
            next: null
        }
    };
}