import React, {useEffect, Suspense, lazy} from 'react';
import {Navigate, Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {MainLoader, removeMainLoader, emotionMixins} from "ui-components";
import MainErrorBoundary from "modules/app/components/MainErrorBoundary";
import AppNotifications from "modules/app/components/AppNotifications";
import {RootState} from "./store";
import MainHeader from "modules/app/components/MainHeader"
import MainNav from "modules/app/components/MainNav";
import {css} from '@emotion/react';
import ServiceIntegration from "modules/integrations/ServiceIntegration"
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import Intercom from "modules/app/components/Intercom";
import _ from "lodash";
import {openModal} from "ui-utils/useModal.ts"

const baseCss = css`
  display: grid;
  grid-template-rows: 72px 1fr;

  grid-template-columns: 1fr;
  height: 100vh;
`;

const containerCss = css`
  overflow: hidden;
  background-color: var(--main-background);
`;

const miniContainerCss = css`
  background-color: var(--main-background);
`;

const mainPaneCss = css`
  position: relative;
  height: 100%;
  padding-left: 72px;
  
  ${emotionMixins.small(`
    padding-left: 48px;
  `)}
`;


const Links = lazy(() => import('./modules/links')/* webpackChunkName: "Links"*/)
const Homepage = lazy(() => import('./modules/homepage')/* webpackChunkName: "Homepage"*/)
const Credentials = lazy(() => import('./modules/credentials')/* webpackChunkName: "Credentials"*/)
const FirstTimeUserPane = lazy(() => import('./modules/app/components/FirstTimeUserPane')/* webpackChunkName: "FirstTimeUserPane"*/)
const NetAppInternal = lazy(() => import('./modules/netapp-internal')/* webpackChunkName: "NetappInternal"*/)
const ServiceAccounts = lazy(() => import('./modules/service-accounts')/* webpackChunkName: "ServiceAccounts"*/)

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: 0,
            refetchOnWindowFocus: false,
            retryOnMount: false,
            staleTime: 120 * 1000
        },
    },
});

const MainPane = () => {
    const serviceIntegrationPaths = useSelector((state: RootState) => state.services.serviceIntegrationPaths);
    const isWorkloadsLoaded = useSelector((state: RootState) => state.services.isWorkloadsLoaded);

    if (!isWorkloadsLoaded) {
        return <MainLoader/>
    }

    return <div css={mainPaneCss}>
        <MainNav/>
        <MainErrorBoundary>
            <Suspense fallback={<MainLoader/>}>
                <Routes>
                    <Route path="/links/*" element={<Links/>}/>
                    <Route path="/credentials/*" element={<Credentials/>}/>
                    <Route path="/netapp-internal/*" element={<NetAppInternal/>}/>
                    <Route path="/service-accounts/*" element={<ServiceAccounts/>}/>
                    <Route path="*" element={<Homepage/>}/>
                    {serviceIntegrationPaths.map(path => {
                        return <Route key="path" path={path + "/*"} element={<ServiceIntegration/>}/>
                    })}
                </Routes>
            </Suspense>
        </MainErrorBoundary>
    </div>
}

const MiniMainPane = () => {
    const isWorkloadsLoaded = useSelector((state: RootState) => state.services.isWorkloadsLoaded);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
            const listener = (event: any) => {
                if (event.data.type === 'SERVICE:ON-READY') {
                    const {payload} = event.data;
                    dispatch({
                        type: "AUTH/MINI-BOOT",
                        payload
                    })
                    dispatch({
                        type: "FEATURES/MINI-INITIALIZE",
                        payload: payload.features
                    })
                    navigate((payload.pathname?.replace("/fsxhome","") || "") + (payload.search ? `${payload.search}` : '') + (payload.hash ? `${payload.hash}` : ''))
                } else if (event.data.type === 'SERVICE:LOCATION-CHANGE') {
                    const {payload} = event.data;
                    const url = payload.pathname?.replace("/fsxhome","")
                    navigate(url)
                } else if (event.data.type === 'SERVICE:FEATURES-CHANGE') {
                    const {payload} = event.data;
                    dispatch({
                        type: "FEATURES/UPDATE",
                        payload: payload.features
                    })
                } else if (event.data.type === 'SERVICE:CONNECTOR-CHANGE') {
                    const {payload} = event.data;
                    dispatch({
                        type: "AUTH/CONNECTOR-UPDATE",
                        payload: { connectorId: payload.connectorId, connectorName: payload.connectorName}
                    })
                } else if (event.data.type === 'SERVICE:TOKEN-UPDATE') {
                    const {payload} = event.data;
                    dispatch({
                        type: "AUTH/TOKEN-UPDATE",
                        payload: { userMetadata: payload.userMetadata, accessToken: payload.accessToken}
                    })
                }
            };

            window.addEventListener('message', listener, false);

            window.parent.postMessage({type: 'SERVICE:READY'}, '*');

            return () => {
                window.removeEventListener("message", listener);
            }
    }, [])

    if (!isWorkloadsLoaded) {
        return <MainLoader/>
    }

    return <div style={{height: '100vh', overflowY: 'hidden'}}>
        <MainErrorBoundary>
            <Suspense fallback={<MainLoader/>}>
                <Routes>
                    <Route path="/links/*" element={<Links/>}/>
                    <Route path="/credentials/*" element={<Credentials/>}/>
                    <Route path="/feedback/*" action={async ()=> openModal('MARKETING/FEEDBACK')} element={<Navigate to={"/"}/>} />
                    <Route path="*" element={<Homepage/>}/>
                </Routes>
            </Suspense>
        </MainErrorBoundary>
    </div>
}



const MainApp = () => {
    const dispatch = useDispatch();
    const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);
    const jobDescription = useSelector((state: RootState) => state.user.jobDescription);
    const isJobDescriptionLoaded = useSelector((state: RootState) => state.user.isJobDescriptionLoaded);
    const userId = useSelector((state: RootState) => state.auth.userId);
    const accountStatus = useSelector((state: RootState) => state.tenancy.accountStatus);
    const selectedAccountId = useSelector((state: RootState) => state.tenancy.selectedAccountId);
    const configs = useSelector((state: RootState) => state.services.workloadConfigs)
    const isIframe = window.top !== window;


    useEffect(() => {
        if (isAuthenticated) {

            dispatch({
                type: "TENANCY/LOAD-ACCOUNTS"
            });

            dispatch({
                type: "USER/LOAD-CONFIG"
            });
        }
    }, [isAuthenticated, userId, dispatch]);

    useEffect(() => {
        if (!isIframe && isAuthenticated && !_.isEmpty(configs)) {
            dispatch({
                type: "FEATURES/INITIALIZE",
                payload: configs
            })
        }

    }, [configs, isAuthenticated, dispatch])

    const isFirstTimeUser = !selectedAccountId || !jobDescription;
    const isAccountAndUserLoaded = accountStatus === "SUCCESS" && isJobDescriptionLoaded;

    if(isIframe){
        return <QueryClientProvider client={queryClient}>
            <div css={baseCss}>
                <div css={miniContainerCss}>
                  <MiniMainPane/>
                </div>
            </div>
        </QueryClientProvider>
    }

    if (!isAuthenticated) {
        return <MainLoader/>;
    }

    return <QueryClientProvider client={queryClient}>
        <div css={baseCss}>
            <MainHeader isFirstTimeUser={isFirstTimeUser}/>
            <div css={containerCss}>
                {!isAccountAndUserLoaded && <MainLoader/>}
                {isAccountAndUserLoaded && <>
                    {isFirstTimeUser && <Suspense><FirstTimeUserPane/></Suspense>}
                    {!isFirstTimeUser && <MainPane/>}
                </>}
            </div>
        </div>
    </QueryClientProvider>
}
const App = React.memo(() => {
    const appState = useSelector((state: RootState) => state.app.appState);
    const isIframe = useSelector((state: RootState) => state.app.isIframe);
    removeMainLoader(false);
    return (
        <>
            {appState === 'loading' && <MainLoader/>}
            <AppNotifications/>
            {isIframe === false && <Intercom/>}
            {appState === 'ready' && <MainApp/>}
        </>
    );
});

export default App;
