import _ from "lodash";
import { setTenancyId, getItemAnon } from "utils/localStorage";
import {setBluexpSource, setIsDemo} from "utils/clients";

interface Account {
    accountPublicId: string
    accountName: string
    userRole: string
    accountSerial: string
}

interface TenancyState {
    accountStatus: string
    selectedAccount?: Account
    selectedAccountId?: string
    accounts?: Account[]
    isDemo: boolean
}

const defaultState: () => TenancyState = () => ({
    accountStatus: "PENDING",
    isDemo: false
});

const demoAccounts = _.chain(import.meta.env.VITE_TENANCY_DEMO_ACCOUNTS).split(";").map(_.trim).reject(_.isEmpty).value();

export default (state = defaultState(), { type, payload }: any): TenancyState => {
    switch (type) {
        case "TENANCY/LOAD-ACCOUNTS:SUCCESS": {
            const { selectedAccountId, accounts } = payload;
            const selectedAccount = _.find(accounts, { accountPublicId: selectedAccountId });
            const isDemo = _.includes(demoAccounts, selectedAccountId) || getItemAnon({ itemKey: `feature.demo-mode` });

            setTenancyId({ tenancyId: selectedAccountId });

            if(isDemo) {
                setIsDemo();
            }

            if(window.top !== window) {
                setBluexpSource();
            }

            return {
                ...state,
                accountStatus: "SUCCESS",
                isDemo,
                selectedAccountId,
                selectedAccount,
                accounts
            }
        }
        case "TENANCY/LOAD-FAILED": {
            return {
                ...state,
                accountStatus: "FAILED"
            }
        }
        default: {
            return state;
        }
    }
};
