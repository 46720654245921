import {  setItem, removeItem, removeItemAnon, setItemAnon, getItemAnon, getItem } from "utils/localStorage";
import {ActionType} from "store";
import platformConfig from "../../../PlatformConfig.json";
import _ from "lodash";

type ActiveMap = {
    [key: string]: Boolean;
}

type Feature = {
    key: string
    title: string
    id: string
    isAnon?: boolean
    isAlwaysOn?: boolean
}

type FeaturesState = {
    byKey: {[key: string]: Feature},
    active: ActiveMap,
    list: Feature[],
    isInitialized: boolean
}

const initialState = () => ({byKey:{}, active: {}, list: [], servicesList: [], isInitialized: false})

export default (state: FeaturesState = initialState(), {payload, type}:ActionType): FeaturesState  => {
    switch (type){
        case "FEATURES/INITIALIZE": {
            const active: ActiveMap = {};
            const features = _.concat(platformConfig.features,_.flatten(payload.filter(config => config.features).map(config => config.features))) || []
            const list: Feature[] = _.map(features, feature => {
                let isFlagEnabled;

                if(feature.isAnon) {
                    isFlagEnabled = getItemAnon({itemKey: `feature.${feature.id}`});
                } else {
                    isFlagEnabled = getItem({itemKey: `feature.${feature.id}`});
                }

                active[feature.id] = isFlagEnabled;

                return {
                    ...feature,
                    key: feature.id
                }
            });

            const byKey = _.keyBy(list, "key");

            return {list, byKey, active, isInitialized: true};
        }
        case "FEATURES/MINI-INITIALIZE": {
            return {...state,...payload, isInitialized: true};
        }
        case "FEATURES/UPDATE": {
            const active: ActiveMap = state.active
            const features = _.concat(state.list ,payload) || []
            const list: Feature[] = _.map(features, feature => {
                let isFlagEnabled;

                if(feature.isAnon) {
                    isFlagEnabled = getItemAnon({itemKey: `feature.${feature.id}`});
                } else {
                    isFlagEnabled = getItem({itemKey: `feature.${feature.id}`});
                }

                active[feature.id] = isFlagEnabled;

                return {
                    ...feature,
                    key: feature.id
                }
            });

            const byKey = _.keyBy(list, "key");

            return {list, byKey, active, isInitialized: true};
        }
        case "FEATURES/TOGGLE": {
            const {feature: featureId, noReload }: { feature: string, noReload?: boolean } = payload;
            const isActive = state.active[featureId];
            const itemKey = `feature.${featureId}`;

            let updatedActive: ActiveMap = { ...state.active };

            if(isActive){
                if(state.byKey[featureId]?.isAnon) {
                    removeItemAnon({itemKey});
                } else {
                    removeItem({itemKey});
                }

                delete updatedActive[featureId]
            } else {
                if(state.byKey[featureId]?.isAnon) {
                    setItemAnon({itemKey}, true);
                } else {
                    setItem({itemKey}, true)
                }

                updatedActive[featureId] = true;
            }

            if(!noReload) {
                window.location.reload();
            }

            return { ...state, active: updatedActive };
        }
        default :{
            return state;
        }
    }
};
