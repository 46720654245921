import {combineReducers, applyMiddleware, compose, Middleware} from 'redux';
import {createBrowserHistory} from 'history';
import dynamicMiddlewares, {addMiddleware, removeMiddleware as _removeMiddleware} from 'redux-dynamic-middlewares';
import {createStaticReducers, createStaticMiddlewares} from "./modules/app"
import {configureStore} from "@reduxjs/toolkit";
import {DynamicStateTypes} from "./types/dynamicStateTypes"

export const history = createBrowserHistory({window});

const initialState = {};

const staticReducers = createStaticReducers();

type asyncReducersType = {
    [key: string]: any
};

const asyncReducers: asyncReducersType = {};

const createReducer = () => {
    return combineReducers({
        ...staticReducers,
        ...asyncReducers
    });
};

const store = configureStore({
        reducer: createReducer(),
        preloadedState: initialState,
        enhancers: (getDefaultEnhancers) => getDefaultEnhancers().concat(compose(applyMiddleware(dynamicMiddlewares))),
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                serializableCheck: false
            })
    }
);

export const registerReducer = (key: string, asyncReducer: any) => {
    asyncReducers[key] = asyncReducer;
    store.replaceReducer(createReducer())
};

export const removeReducer = (key: string) => {
    delete asyncReducers[key];
    store.replaceReducer(createReducer())
};

type namedMiddlewaresType = {
    [key: string]: Middleware
};

const namedMiddlewares: namedMiddlewaresType = {};
export const registerMiddleware = (middleware: Middleware, key?: string) => {
    if (key) {
        const existing = namedMiddlewares[key];
        if (existing) {
            _removeMiddleware(existing);
        }
        namedMiddlewares[key] = middleware;
    }
    addMiddleware(middleware)
};

export const removeMiddleware = (key: string) => {
    const middleware = namedMiddlewares[key];
    delete namedMiddlewares[key];
    _removeMiddleware(middleware);
};

addMiddleware(...createStaticMiddlewares());

type registeredModulesType = {
    [key: string]: boolean
};

const registeredModules: registeredModulesType = {}
export const registerModule = (name: string) => {
    registeredModules[name] = true;
}

export const removeModule = (name: string) => {
    registeredModules[name] = false;
}

export const isModuleRegistered = (name: string) => {
    return registeredModules[name] || false;
}

export type ActionType = {
    type: string,
    payload: any
}

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState> & DynamicStateTypes
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export default store;