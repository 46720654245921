import { getItem, removeItem, setItem } from "utils/localStorage";
import { apiClient } from 'utils/clients';
import _ from "lodash";
import {history} from "store";
import querystring from "query-string";
import {Middleware} from "redux";
import {handleSingleAction} from "../../../utils/actions";
import {setFullStoryUserValues} from "../../../utils/analytics";

const fetchOrSetStoredItem = ({ storageItemKey, collection, collectionItemKey, preSetItem }:{storageItemKey: {itemKey:string, includeTenancyId?: boolean, userId: string}, collection: any, collectionItemKey: string, preSetItem: any}) => {
    const storedItemId = preSetItem || getItem(storageItemKey);

    let selectedItem = null;

    if (storedItemId) {
        selectedItem = _.find(collection, { [collectionItemKey]: storedItemId });
    }

    if (!selectedItem && collection.length > 0) {
        selectedItem = collection[0];
        setItem(storageItemKey, selectedItem[collectionItemKey]);
    } else if (!selectedItem) {
        removeItem(storageItemKey);
        return null;
    } else if (preSetItem) {
        setItem(storageItemKey, selectedItem[collectionItemKey]);
    }

    return selectedItem[collectionItemKey];
};


const tenancyMiddleware: Middleware = ({ dispatch, getState }) => next => async (action) => {
    const { type, payload } = action;
    const response = next(action);

    if (type === "TENANCY/LOAD-ACCOUNTS") {
        const tenancy = getState().tenancy;

        if (tenancy.accountStatus !== "PENDING") {
            return;
        }

        const search = querystring.parse(history.location.search);
        const preSetAccountId = search.accountId;

        if (search.accountId) {
            delete search.accountId;
            const searchString = querystring.stringify(search);

            history.replace(history.location.pathname + (searchString ? `?${searchString}` : ''));
        }

        try {
            const state = getState();
            const localStorageItemKey = { itemKey: "userAccountId", userId: state.auth.userId };
            const { data: accounts } = payload?.accounts ? { data: payload.accounts } : await apiClient.get(`${import.meta.env.VITE_TENANCY_API_URL}/account`, {headers:{"x-agent-id": undefined}});

            let selectedAccountId = fetchOrSetStoredItem({ storageItemKey: localStorageItemKey, collection: accounts, collectionItemKey: "accountPublicId", preSetItem: preSetAccountId });
            if(window.top !== window){
                selectedAccountId = getState().auth.accountId
            }
            setFullStoryUserValues({ "AccountId": selectedAccountId });
            dispatch({
                type: "TENANCY/LOAD-ACCOUNTS:SUCCESS",
                payload: {
                    selectedAccountId,
                    accounts
                }
            });
        } catch (e) {
            dispatch({
                type: "TENANCY/LOAD-FAILED",
                payload: e
            })

            dispatch({
                type: "NOTIFICATIONS:ERROR",
                payload: {
                    isFatal: true,
                    message: "Failed to load accounts",
                    exception: e
                }
            })
        }
    } else if (type === "TENANCY/SET-ACCOUNT") {
        const { accountId, redirectMessage } = payload;
        const userId = getState().auth.userId;

        setItem({ itemKey: "userAccountId", userId }, accountId);

        if(redirectMessage) {
            window.location.hash = `#redirectMessage=${redirectMessage}`;
        }
        window.location.reload();
    } else if (type === "TENANCY/CREATE-ACCOUNT") {
        const { accountName } = payload;

        return handleSingleAction(action, async () => {
            const account = await apiClient.post(`${import.meta.env.VITE_TENANCY_API_URL}/account/${accountName}`, {});
            return _.get(account, 'data.accountPublicId');
        }).catch((e) => {
            dispatch({
                type: "NOTIFICATIONS:ERROR",
                payload: {
                    exception: e,
                    group: "TENANCY/CREATE-ACCOUNT",
                    message: "Failed to create account."
                }
            })

            throw e;
        })
    }

    return response;
}

export default tenancyMiddleware